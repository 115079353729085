import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import {
  requireCheck,
  resetRequired,
  validateGst,
  randomString,
  isDummyMail,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  checkNumber,
  authCheck,
  ADMIN,
} from '../../utils/utils';
import toast, { Toaster } from 'react-hot-toast';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const CustomerPage = () => {
  const [customerData, setCustomerData] = useState('');
  const [role, setRole] = useState('');
  const [userType, setUserType] = useState('');
  const [userCode, setUserCode] = useState('');
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [contactPerson1, setContactPerson1] = useState('');
  const [contactPhone1, setContactPhone1] = useState('');
  const [contactEmail1, setContactEmail1] = useState('');
  const [contactPerson2, setContactPerson2] = useState('');
  const [contactPhone2, setContactPhone2] = useState('');
  const [contactEmail2, setContactEmail2] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [CINnumber, setCINnumber] = useState('');
  const [GSTnumber, setGSTnumber] = useState('');
  const [preRegisteredEmail, setPreRegisteredEmail]: any = useState([]);
  const getApi = () => {
    try {
      axios
        .get(`${baseUri()}users`, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          const emailArr: any = [];
          response.data.forEach((t: any) => {
            emailArr.push(t.email);
          });
          setPreRegisteredEmail(emailArr);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const authState = useContext(GlobalStateData);

  useEffect(() => {
    authCheck(authState.authData);
    setRole(2);
    setUserType(2);
    hideLoader();
  }, []);

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'userCode':
        setUserCode(event.target.value);
        break;
      case 'name':
        setName(event.target.value);
        break;
      case 'shortName':
        setShortName(event.target.value);
        break;
      case 'address1':
        setAddress1(event.target.value);
        break;
      case 'address2':
        setAddress2(event.target.value);
        break;
      case 'address3':
        setAddress3(event.target.value);
        break;
      case 'city':
        setCity(event.target.value);
        break;
      case 'district':
        setDistrict(event.target.value);
        break;
      case 'state':
        setState(event.target.value);
        break;
      case 'pincode':
        setPincode(checkNumber(event.target, '6.0'));
        break;
      case 'phone1':
        setPhone1(checkNumber(event.target, '10.0'));
        break;
      case 'phone2':
        setPhone2(checkNumber(event.target, '10.0'));
        break;
      case 'contactPerson1':
        setContactPerson1(event.target.value);
        break;
      case 'contactPhone1':
        setContactPhone1(checkNumber(event.target, '10.0'));
        break;
      case 'contactEmail1':
        setContactEmail1(event.target.value);
        break;
      case 'contactPerson2':
        setContactPerson2(event.target.value);
        break;
      case 'contactPhone2':
        setContactPhone2(checkNumber(event.target, '10.0'));
        break;
      case 'contactEmail2':
        setContactEmail2(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      case 'rePassword':
        setRePassword(event.target.value);
        break;
      case 'CINnumber':
        setCINnumber(event.target.value);
        break;
      case 'GSTnumber':
        setGSTnumber(event.target.value);
        break;
    }
  };

  const clearFormData = () => {
    setUserCode('');
    setName('');
    setShortName('');
    setAddress1('');
    setAddress2('');
    setAddress3('');
    setCity('');
    setDistrict('');
    setState('');
    setPincode('');
    setPhone1('');
    setPhone2('');
    setContactPerson1('');
    setContactPhone1('');
    setContactEmail1('');
    setContactPerson2('');
    setContactPhone2('');
    setContactEmail2('');
    setEmail('');
    setPassword('');
    setRePassword('');
    setCINnumber('');
    setGSTnumber('');
    resetRequired();
  };

  const postApi = () => {
    const postData = {
      role,
      userType,
      userCode,
      name,
      shortName,
      address1,
      address2,
      address3,
      city,
      district,
      state,
      pincode,
      phone1,
      phone2,
      contactPerson1,
      contactPhone1,
      contactEmail1,
      contactPerson2,
      contactPhone2,
      contactEmail2,
      email,
      username: email,
      password,
      repassword,
      CINnumber,
      GSTnumber,
    };
    showLoader();
    getApi();
    let validate = requireCheck(postData, customerData, 'userCode');
    let gstVal = validateGst();
    if (!validate || !gstVal) {
      return false;
    }
    if (preRegisteredEmail.includes(email)) {
      toast.error('Email already in use');
      return;
    }
    if (password.length < 6) {
      toast.error('Password must be atleast 6 characters long');
      return;
    }
    if (password !== repassword) {
      toast.error(`Password and confirm password should be same`);
      return;
    }
    for (const key in postData) {
      if (postData[key] == '') {
        delete postData[key];
      }
    }
    delete postData.repassword;
    axios
      .post(baseUri() + 'users', postData, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        clearFormData();
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  return (
    <>
      <SEO title="Create Account" />
      <Toaster />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Create Account</header>
            <CardBody>
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    User Code <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="userCode"
                      placeholder="User Code *"
                      onChange={(e) => handleChange(e)}
                      value={userCode}
                      className="userCode require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Name <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name *"
                      onChange={(e) => handleChange(e)}
                      value={name}
                      className="name require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Email <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="email"
                      name="email"
                      placeholder="Login Email *"
                      onChange={(e) => handleChange(e)}
                      value={email}
                      className="email require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Password <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password (min 6 characters) *"
                      onChange={(e) => handleChange(e)}
                      value={password}
                      className="password require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Confirm Password <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="password"
                      name="rePassword"
                      placeholder="Confirm Password (min 6 characters) *"
                      onChange={(e) => handleChange(e)}
                      value={repassword}
                      className="rePassword require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Short Name <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="shortName"
                      placeholder="Short Name *"
                      onChange={(e) => handleChange(e)}
                      value={shortName}
                      className="shortName require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Address 1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="address1"
                      placeholder="Address 1 *"
                      onChange={(e) => handleChange(e)}
                      value={address1}
                      className="address1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Address 2</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="address2"
                      placeholder="Address 2"
                      onChange={(e) => handleChange(e)}
                      value={address2}
                      className="address2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Address 3</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="address3"
                      placeholder="Address 3"
                      onChange={(e) => handleChange(e)}
                      value={address3}
                      className="address3"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    City <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="city"
                      placeholder="City *"
                      onChange={(e) => handleChange(e)}
                      value={city}
                      className="city require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    District <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="district"
                      placeholder="District *"
                      onChange={(e) => handleChange(e)}
                      value={district}
                      className="district require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    State <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="state"
                      placeholder="State *"
                      onChange={(e) => handleChange(e)}
                      value={state}
                      className="state require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    PinCode <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="pincode"
                      maxLength="6"
                      placeholder="PinCode *"
                      onChange={(e) => handleChange(e)}
                      value={pincode}
                      className="pincode require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Phone #1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="phone1"
                      placeholder="Phone #1 *"
                      maxLength="10"
                      onChange={(e) => handleChange(e)}
                      value={phone1}
                      className="phone1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Phone #2</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="phone2"
                      placeholder="Phone #2"
                      maxLength="10"
                      onChange={(e) => handleChange(e)}
                      value={phone2}
                      className="phone2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>User CIN</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="CINnumber"
                      maxlength="21"
                      placeholder="User CIN"
                      onChange={(e) => handleChange(e)}
                      value={CINnumber}
                      className="CINnumber"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>User GST No.</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="GSTnumber"
                      maxLength="15"
                      placeholder="User GST No."
                      onChange={(e) => handleChange(e)}
                      value={GSTnumber}
                      className="GSTnumber validateGst"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    User Contact Person #1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPerson1"
                      placeholder="User Contact Person #1 *"
                      onChange={(e) => handleChange(e)}
                      value={contactPerson1}
                      className="contactPerson1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    User Contact Person #1 Phone No. <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPhone1"
                      maxLength="10"
                      placeholder="User Contact Person #1 Phone No. *"
                      onChange={(e) => handleChange(e)}
                      value={contactPhone1}
                      className="contactPhone1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>User Contact Person #1 eMail</label>
                  <Input fullWidth size="Small">
                    <input
                      type="email"
                      name="contactEmail1"
                      placeholder="User Contact Person #1 eMail"
                      onChange={(e) => handleChange(e)}
                      value={contactEmail1}
                      className="contactEmail1"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>User Contact Person #2</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPerson2"
                      placeholder="User Contact Person #2"
                      onChange={(e) => handleChange(e)}
                      value={contactPerson2}
                      className="contactPerson2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>User Contact Person #2 Phone No.</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPhone2"
                      maxLength="10"
                      placeholder="User Contact Person #2 Phone No."
                      onChange={(e) => handleChange(e)}
                      value={contactPhone2}
                      className="contactPhone2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>User Contact Person #2 eMail</label>
                  <Input fullWidth size="Small">
                    <input
                      type="email"
                      name="contactEmail2"
                      placeholder="User Contact Person #2 eMail"
                      onChange={(e) => handleChange(e)}
                      value={contactEmail2}
                      className="contactEmail2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default CustomerPage;
